<template>
  <div class="container-fluid bg-image" ref="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 text-center">
        <div class="centered-content">
          <router-link to="/"><img src="../assets/barden.png" class="mt-3" alt="Company Logo"></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.setDynamicStyles();
    window.addEventListener('resize', this.setDynamicStyles);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDynamicStyles);
  },
  methods: {
    setDynamicStyles() {
      const screenWidth = window.innerWidth;
      const container = this.$refs.container;

      // Use $nextTick to wait for the next DOM update cycle
      this.$nextTick(() => {
        // Check if container is available
        if (container) {
          // Set background size
          const backgroundSize = screenWidth <= 767 ? '800px auto ' : 'auto auto';
          container.style.backgroundSize = backgroundSize;

          // Set min-height
          const minHeight = screenWidth <= 767 ? '300px' : 'auto';
          container.style.minHeight = minHeight;
        }
      });
    }
  }
};
</script>


<style scoped>
  .container-fluid {
    background-image: url('https://www.topgear.com/sites/default/files/images/big-read/2020/03/4ad862f9ad2524a65418a395844fa0ac/topgear_-_a45_vs_rsq3-002.jpg');
    background-position: center;
    background-repeat: repeat; /* Repeat both horizontally and vertically */
    color: #fff; /* Text color on the image */
    text-align: center;
    height: 750px;
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .mt-3 {
    padding: 15px;
    border-radius: 10px;
    display: inline-block; /* Ensure inline-block display for proper centering */
    width: 250px;
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    .container-fluid{
      height: 400px;
    }

  }

  @media only screen and (min-width: 768px) {

    .mt-3{
      width: 700px;
    }
  }
</style>
