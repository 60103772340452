<template>
<div class="container-fluid indiv-cont">
    <div class="container">
        <div v-for="(entry, index) in entries" :key="index">
            <!-- Image Slider -->
            <div class="indivCar-Info" v-if="entry.id == carId">
                <h2 id="title">{{ entry.name }}</h2>

            </div>
            <div v-if="entry.id == carId" class="img-slider">
                <Splide :options="{ rewind: true }" class="splide-cont">
                    <SplideSlide class="text-center splide-stuff">
                        <img :src="entry.mainImage.fields.file.url" alt="Car Image" class="auto-size slider-image">
                    </SplideSlide>
                    <SplideSlide v-for="(image, index) in entry.otherImages || []" :key="index" class="text-center">
                        <img v-if="image && image.fields && image.fields.file" :src="image.fields.file.url" alt="Other Image" class="auto-size slider-image">
                    </SplideSlide>
                </Splide>
            </div>
            <!-- Individual Car Information -->
            <div v-if="entry.id == carId">
                <p id="description">{{ entry.description }}</p>
            </div>
            <!-- Other Car Information and WhatsApp Button -->
            <div v-if="entry.id == carId" class="indivCar-final">
                <div class="car-properties">
                    <p class="car-property">Odometer: <span class="car-property-value">{{ entry.odometer }}</span></p>
                    <p class="car-property">Fuel Type: <span class="car-property-value">{{ entry.fuelType }}</span></p>
                    <p class="car-property">Transmission: <span class="car-property-value">{{ entry.transmission }}</span></p>
                    <p class="car-property">Doors: <span class="car-property-value">{{ entry.doors }}</span></p>
                    <p class="car-property">Engine Size: <span class="car-property-value">{{ entry.engineSize }}</span></p>
                    <p class="car-property">Body Type: <span class="car-property-value">{{ entry.body }}</span></p>
                    <p class="car-property">Year: <span class="car-property-value">{{ entry.year }}</span></p>
                    <p class="car-property">Colour: <span class="car-property-value">{{ entry.colour }}</span></p>
                </div>
            </div>
            <button class="whatsapp-button" v-if="entry.id == carId" @click="openWhatsApp(entry.name)">
              <i class="fab fa-whatsapp"></i>
              Contact via WhatsApp</button>

        </div>
    </div>
</div>
</template>

<script>
import {
    useAllContentfulEntries
} from "@/composables/getEntries";
import {
    useRoute
} from "vue-router";
import {
    ref
} from "vue";
import {
    Splide,
    SplideSlide
} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
export default {
    setup() {
        const {
            entries,
            loading,
            error
        } = useAllContentfulEntries();
        const route = useRoute();
        const carId = ref(route.params.ID);

        console.log("Entries: ", entries);

        return {
            entries,
            carId
        };
    },
    components: {
        Splide,
        SplideSlide
    },
    methods: {
        openWhatsApp(name) {
            const phoneNumber = '+447585213117';
            const message = encodeURIComponent(`Hi, I would like to know more about ${name} and whether it is available or not.`);
            const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
            window.open(whatsappLink, '_blank');
        },
    }
};
</script>

<style scoped>
.indiv-cont {
    background-color: #000717;
    min-height: 100vh;
    padding: 0;
    margin: 0px;
}

.img-slider {
    /* Your styling here */
    background-color: #eee;
    border-radius: 10px;
    height: auto;
    min-height: 500px;
    max-height: 800px;
}

.slider-image {
    width: 100%;
    /* Adjust the width as needed */
    height: auto;
    /* Ensures the aspect ratio is maintained */
    min-height: 500px;
    max-height: 800px;
    border-radius: 15px;
    /* Add any other styling as needed */
}

#title {
    font-size: 2rem;
    /* Larger font size for the title */
    font-weight: bold;
    /* Bold font weight */
    color: #fff;
    /* White text color */
    border-bottom: 2px solid #fff;
    /* Bottom border for the title */
    padding-bottom: 10px;
    /* Adjust spacing between title and description */
    margin-bottom: 20px;
    /* Adjust margin as needed */
}

#description {
    color: #ccc;
    /* Lighter text color for description */
    margin-bottom: 20px;
    /* Adjust margin as needed */
    background-color: #1a1a2e;
    /* Dark blue/purple background color */
    padding: 20px;
    /* Adjust padding as needed */
    border-radius: 10px;
    /* Rounded corners for the container */
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    /* Apply equal spacing to all text within this container */

    border: 2px solid #fff;
    /* Border around the container */
}

/* Styling for dark blue/purple background */
.indivCar-Info {
    background-color: #1a1a2e;
    /* Dark blue/purple background color */
    display: inline-block;
    padding: 20px;
    /* Adjust padding as needed */
    border-radius: 10px;
    /* Rounded corners for the container */
    padding-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #fff;
    /* Border around the container */
}



.car-properties {
    font-family: Arial, sans-serif;
    /* Specify the font family */
    display: inline;
    color: #eee;
    /* Add a border for separation */
    padding: 20px;
    /* Add padding for spacing */
    border-radius: 5px;
    /* Add border radius for rounded corners */
}

.car-property {
    font-size: 16px;
    /* Set font size */
    display: inline-block;
    border: 1px solid #eee;
    margin: 10px;
    margin-left: 0;
    padding: 0.5rem;
    line-height: 1.5;
    /* Set line height for better readability */
}

.car-property-value {
    font-weight: bold;
    /* Make property values bold */
}

@media screen and (max-width: 767px) {
    .img-slider {
        max-height: 400px;
        min-height: 400px;
        height: 400px;
    }

    .slider-image {
        height: 400px;
        /* Ensures the aspect ratio is maintained */
        max-height: 400px;
        min-height: 400px;

    }
}

.whatsapp-button {
  display: inline-block;
  background-color: #25D366; /* WhatsApp green */
  color: #fff; /* White text color */
  text-decoration: none; /* Remove underline */
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.whatsapp-button:hover {
  background-color: #128C7E; /* Darker shade of green on hover */
}

.whatsapp-button i {
  margin-right: 5px; /* Margin between icon and text */
}
</style>
