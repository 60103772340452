<template>
    <div class="container-fluid finance-cont-fluid">
      <div class="container finance-cont">
        <img id="title" src="../assets/warranty.jpg">
      </div>
  
      <div class="buy-your-car container-fluid">
        <div class="container">
          <div class="row main-title">
            <!-- First column (1/3) -->
            <div class="col-12 col-sm-4">
              <div class="buy-your-car-column">
                <!-- Your content for the first column goes here -->
                <img id="sell-car-img" src="../assets/finance.jpeg" alt="">
              </div>
            </div>
    
            <!-- Second column (2/3) -->
            <div class="col-12 col-sm-8">
              <div class="buy-your-car-column">
                <!-- Your content for the second column goes here -->
                <h1>We have you covered</h1>
                <p>Get in touch to hear your best options</p>
                
    
                <div class="title-info">
                  <p>
                    At Barden Motor group we offer warranty protection with Warranty Assist on all of our used cars so you can drive away happy with complete peace of mind.

                    The warranty protects your car by covering the costs of repairs and maintenance to help keep you on the road.
                    
                    We are also able to offer extendable options on your new purchase for 6, 12 or 24 month extensions.
                  </p>                </div>
              </div>
            </div>
          </div>
    
          <div class="other">
            <button class="whatsappButton btn">Contact via Whatsapp</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      // Replace '123456789' with the desired phone number
      const phoneNumber = '+447464873211';
  
      // Replace 'Hello%20World' with the pre-determined message
      const message = `Hello! I would like to discuss what options are available regarding warranty. Looking forward to hearing from you!`;
  
      const whatsappButton = document.querySelector('.whatsappButton');
  
      whatsappButton.addEventListener('click', function() {
        // Construct the WhatsApp link
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  
        // Open the link in a new tab or window
        window.open(whatsappLink, '_blank');
      });
    },
  };
  </script>
  
  <style scoped>

  
  #title {
    text-align: center;
    color: white;
    margin: 0 auto;
    width: 375px; /* or any specific width */
    margin-bottom: 20px;
    background-color: grey;
  }

.finance-cont-fluid {
    background-color: #000717;
    min-height: 100vh;
    width: 100%;
}

.finance-cont{
  text-align: center;

}



#sell-car-img {
    width: 400px;
    height: auto;
  }
  
  @media screen and (max-width: 767px) {
    #sell-car-img {
      width: 100%;
    }
  }
  
  .buy-your-car {
    background-color: #000717;
    min-height: 100vh;
    color: #ffffff;
    padding-bottom: 100px;
  }
  
  .buy-your-car-column > h1 {
    padding: 10px;
    padding-left: 0px;
  }
  
  .title-info {
    background-color: #29292b;
    padding: 20px;
    border-radius: 8px;
    min-height: 18rem;
  }
  
  .title-info p {
    color: #eee;
  }
  
  .main-title {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .whatsappButton {
    background-color: #333; /* Dark background color */
    color: #fff; /* Text color */
    border: 2px solid #555; /* Border color */
    padding: 15px; /* Padding for the button */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Add pointer cursor for interaction */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition effect */
    width: 100%; /* Full width by default */
  }
  
  /* Media query for screens larger than 992px (laptop size) */
  @media (min-width: 992px) {
    .whatsappButton {
      width: auto; /* Auto width for screens larger than 992px */
      margin: 0 auto; /* Center the button */
      max-width: 400px; /* Limit maximum width */
      padding: 20px; /* Generous padding for larger screens */
    }
  }
  
  .whatsappButton:hover {
    background-color: #555; /* Darker background color on hover */
    color: #fff; /* Lighter text color on hover */
  }
</style>
