<template>
  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg navbar-dark shadow fixed-top" style="background-color: #000717;">
    <div class="container">
      <button 
      class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item active">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/cars">Used Cars</router-link>

          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>

          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/sell-your-car">Sell your car</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/warranty">Warranty</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// Your script code
</script>

<style scoped>
/* Your scoped styles */
.navbar {
position: relative;
}

</style>
