<template>
    <div class="footer-basic">
        <footer>
            <ul class="list-inline">
                <li class="list-inline-item"><router-link to="/">Home</router-link></li>
                <li class="list-inline-item"><router-link to="/about">About</router-link></li>
                <li class="list-inline-item"><router-link to="/cars">Used Cars</router-link></li>
                <li class="list-inline-item"><router-link to="/warranty">Warranty</router-link></li>
                <li class="list-inline-item"><router-link to="/sell-your-car">Sell your car</router-link></li>
                <li class="list-inline-item"><router-link to="/contact">Contact</router-link></li>
            </ul>
            <p class="copyright">Barden Motor Group Ltd © 2023</p>
        </footer>
    </div>
</template>

<style>
.footer-basic {
    padding:40px 0;
    background-color:#110F16;
    color:#4b4c4d;
  }
  
  .footer-basic ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
  }
  
  .footer-basic li {
    padding:0 10px;
  }
  
  .footer-basic ul a {
    color:white;
    text-decoration:none;
    opacity:0.8;
  }
  
  .footer-basic ul a:hover {
    opacity:1;
  }
  
  .footer-basic .social {
    text-align:center;
    padding-bottom:25px;
  }
  
  .footer-basic .social > a {
    font-size:24px;
    width:40px;
    height:40px;
    line-height:40px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    border:1px solid white;
    margin:0 8px;
    color:inherit;
    opacity:0.75;
  }
  
  .footer-basic .social > a:hover {
    opacity:0.9;
  }
  
  .footer-basic .copyright {
    margin-top:15px;
    text-align:center;
    font-size:13px;
    color:white;
    margin-bottom:0;
  }
</style>