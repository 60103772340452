<template>
  <div class="home">
    <!-- Full Page Image Header with Vertically Centered Content -->
    <SlicedText/>

      <!-- Your additional content here -->
      <div class="container-fluid main-content-cont" style="background-color: #110F16;">


       



      <CarCard/>
      <AboutUsInfo/>


    </div>
  </div>
</template>

<script>
import SlicedText from '../components/SlicedText.vue'
import CarCard from '../components/CarCard.vue'
import AboutUsInfo from '../components/AboutUsInfo.vue'

  export default{
    components: {
      SlicedText,
      CarCard,
      AboutUsInfo
    }
  }
</script>

<style scoped>

#title {
  font-size: 3em;
  font-weight: bolder;
}


.order-card {
  color: #fff;
}


.bg-c-green {
  background-color: #000717;
}


                                  
.main-content-cont{
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 2em; /* Adjust as needed */
  padding: 0px;
}

.card{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}


</style>
