<template>
    <div class="container-fluid">
        <div class="container">
            <img src="../assets/barden.png" alt="" >
            <br/>
            <p class="text-center" style=" margin: auto; max-width: 750px;">Welcome to Barden Motor Group Ltd., your premier destination for high-quality used cars and exceptional automotive services. At Barden Motor Group, we take pride in providing our valued customers with a seamless and enjoyable car-buying experience. As a trusted local car selling company, we specialize in offering a diverse range of meticulously inspected and competitively priced used cars that cater to a variety of preferences and budgets.</p>
<!-- <p class="text-center" style=" margin: auto; max-width: 750px;">One of the key features that sets Barden Motor Group apart is our commitment to making car ownership accessible to everyone. We understand that financing plays a crucial role in the decision-making process, and that's why we offer flexible and tailored financing options to suit your individual needs. Our team of finance experts is dedicated to assisting you in finding the best solution, ensuring that your dream car is not only within reach but also affordable.</p>
<p class="text-center" style=" margin: auto; max-width: 750px;">Looking to upgrade or simply ready to part ways with your current vehicle? Barden Motor Group is here to simplify the process. We buy cars, providing a hassle-free and transparent approach to selling your vehicle. Our team of experts will assess your car's value fairly, offering you a competitive price and a quick, stress-free transaction.</p>
<p class="text-center" style=" margin: auto; max-width: 750px;">With a commitment to customer satisfaction, integrity, and excellence, Barden Motor Group Ltd. has become a trusted name in the local automotive industry. Explore our extensive inventory, take advantage of our financing options, and experience the ease of selling your car with us. Welcome to Barden Motor Group Ltd., where your automotive journey begins with quality, transparency, and unparalleled service.</p>
--> 
</div>
    </div>

</template>

<style scoped>
.container img{
    width: 100%;
    max-width: 500px;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Horizontally center the content */
    align-items: center;     /* Vertically center the content */
}
.container p{
    margin: 0px;
    padding: 2em;
}
.container-fluid{
    background-color: #110F16;
    color: white;

}
</style>