// contentful.js (All Entries Composable)
import { ref, onMounted } from 'vue';
const contentful = require('contentful');

const client = contentful.createClient({
  space: 'h8b6x97ke74y',
  environment: 'master', // defaults to 'master' if not set
  accessToken: 'HVBmfWmZmuginExP9P0Bkr1I4cM-M6ggKXZbd8Xsj9o'
});

export function useAllContentfulEntries() {
  const entries = ref([]);
  const loading = ref(true);
  const error = ref(null);

  onMounted(() => {
    client.getEntries()
      .then((response) => {
        entries.value = response.items.map((item) => item.fields);
        loading.value = false;
        console.log(entries)
      })
      .catch((err) => {
        error.value = err.message;
        loading.value = false;
      });
  });

  return { entries, loading, error };
}
