<template>
    <div class="container-fluid About-cont">
        <div class="container">
            <h1 class="big-title">Cruising Excellence: Our Story, Values, and Commitment to Your Car Buying Experience</h1>
        </div>

        <About4Cards v-if="showAbout4Cards" />


        <div class="container-fluid general-about">
            <div class="container text-cont">
                <div class="text">
                    <h3>Direct Business with us</h3>
                    <p>Centrally located in the UK, Barden Motor Group Ltd. is your direct connection to local car sales. With no intermediaries, our proximity fosters personalized customer service, ensuring a transparent, trustworthy, and tailored experience. Choosing us means forging lasting community relationships and experiencing the advantage of direct transactions with a commitment to excellence.</p>                </div>
                <div class="text">
                    <h3>Always Here for You</h3>
                    <p>At Barden Motor Group Ltd., we understand that your automotive needs don't adhere to a strict schedule. That's why our commitment to customer support knows no bounds. With a dedicated team ready to assist you 24/7, we ensure that whether you have a query about our extensive inventory, need assistance with financing options, or require information on selling your car, help is just a call or message away. Experience the convenience of round-the-clock customer support, because at Barden Motor Group, we're always here for you.</p>
                </div>
                <div class="text">
                    <h3>Explore a Dynamic Inventory</h3>
                    <p>Discover the thrill of a constantly evolving inventory at Barden Motor Group Ltd. Our commitment to providing you with the latest and greatest in the automotive world means that our stock is always updating. With new arrivals regularly gracing our showroom, you can anticipate a fresh selection of high-quality used cars, each one waiting to embark on a new journey with you. Stay tuned for exciting additions to our dynamic inventory, where your dream car may be just a visit away.</p>
                </div>
            </div>
        </div>
        <About4Cards v-if="!showAbout4Cards" />

    </div>
</template>

<script>
import About4Cards from '../components/About4Cards.vue'

export default {
  components: {
    About4Cards
  },
  data() {
    return {
      showAbout4Cards: false
    };
  },
  mounted() {
    // Check if the screen width is greater than 600px (adjust as needed)
    if (window.innerWidth > 600) {
      this.showAbout4Cards = true;
    }

    // Listen for window resize events to toggle the visibility
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // Remove the resize event listener to prevent memory leaks
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      // Toggle visibility based on screen width
      this.showAbout4Cards = window.innerWidth > 600;
    }
  }
};
</script>

<style scoped>
.About-cont{
    min-height: 100vh;
    background-color: #000717;
    padding: 0;
}
.big-title {
    background: linear-gradient(45deg, #6b0080, #330044); /* Darker Purple Gradient */
    color: white; /* Text color */
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.8); /* White text shadow */
    padding: 1em;
    text-align: center;
    font-size: 3em; /* Default font size for larger screens */
  
    /* Media queries for smaller screens */
    @media only screen and (max-width: 320px) {
      font-size: 2em; /* Adjust font size for screens up to 320px */
    }
  
    @media only screen and (min-width: 600px) {
      font-size: 3em; /* Adjust font size for screens 600px and larger */
    }
  
    @media only screen and (min-width: 992px) {
      font-size: 5em; /* Adjust font size for screens 992px and larger */
    }
  }

.general-about{
    background-color: #140f22;
    padding: 0;
    margin: 0px;
}

.text-cont{
    padding: 2.5em;
}
.text{
    margin: 1em;
    color: white;
    text-align: justify;
    line-height: 2rem;
    margin-bottom: 3rem;
}
.text > h3{
  text-align: left;
}
</style>