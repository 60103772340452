import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UsedCars from '../views/UsedCars.vue'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import IndivCar from '../views/IndivCar.vue'
import Finance from '../views/Finance.vue'
import SellYourCar from '../views/SellYourCar.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cars',
    name: 'used cars',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UsedCars
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Contact
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/cars/:ID',
    name: 'IndivCar',
    component: IndivCar
  },
  {
    path: '/warranty',
    name: 'warranty',
    component: Finance
  },
  {
    path: '/sell-your-car',
    name: 'sell-your-car',
    component: SellYourCar
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
