<template>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3 mb-4">
            <div class="card border-0" id="card1">
              <div class="card-body">
                <h5 class="card-title">Where are we</h5>
                <p class="card-text">We are located locally within Lancashire with a great emphasis on customer service and making you feel comfortable </p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-lg-3 mb-4">
            <div class="card border-0" id="card2">
              <div class="card-body">
                <h5 class="card-title">Our mission</h5>
                <p class="card-text">This company is more than just a business, with goals beyond profit with a focus on building spectacular relations and a trustable name</p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-lg-3 mb-4">
            <div class="card border-0" id="card3">
              <div class="card-body">
                <h5 class="card-title">Want to know more</h5>
                <p class="card-text">Feel free to contact us at any time via our contact form or by any of our social media and expect a quick friendly message back</p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-lg-3 mb-4">
            <div class="card border-0" id="card4">
              <div class="card-body">
                <h5 class="card-title">Why us</h5>
                <p class="card-text">We are local first and foremost making us a great apparent option for business and your interactions are done directly to us with no middle man or third party</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // Your component logic here
  };
  </script>

  <style scoped>

  .container{
    margin-top: 1em;
  }
  .card-text{
    color: white;
  }
    @media only screen and (max-width: 600px) {
        .card-text{
            color: white;
            min-height: 5em;

          }

    }
    @media only screen and (min-width: 600px) {
        .card-text{
            color: white;
            min-height: 10em;

          }

    }
    @media only screen and (min-width: 992px) {
        .card-text{
            color: white;
            min-height: 12em;

          }
          #card4 {
            border-bottom-right-radius: 20px;
        
          }
          #card1 {
            border-bottom-left-radius: 20px;
        
          }

    }
  
  #card1 {
    background-color: #6E006F; /* Dark Magenta */

  }
  
  #card2 {
    background-color: #8B006B; /* Plum */
  }
  
  #card3 {
    background-color: #A00057; /* Aubergine */
  }
  
  #card4 {
    background-color: #C10043; /* Wine Red */

  }

/* Default styles for mobile */
  .big-title {
    font-size: 3em; /* Adjust font size for smaller screens */
  }
  
  .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  /* Styles for screens larger than iPads but smaller than laptops */
  @media only screen and (min-width: 600px) {
    .big-title {
      font-size: 4em; /* Adjust font size for medium screens */
    }
  
    .col-lg-3 {
      flex: 0 0 50%; /* Display 2 cards in a row */
      max-width: 50%; /* Display 2 cards in a row */
    }
  }
  
  /* Styles for laptops and above */
  @media only screen and (min-width: 992px) {
    .big-title {
      font-size: 5em; /* Adjust font size for larger screens */
    }
  
    .col-lg-3 {
      flex: 0 0 25%; /* Display 4 cards in a row */
      max-width: 25%; /* Display 4 cards in a row */
    }
  }
</style>
  