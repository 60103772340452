<template>
  <div class="container-fluid">
    <div class="container text-center title">
      <h1>Fill the form, it's easy</h1>
    </div>
    <div class="container mt-5">
      <form @submit.prevent="sendEmail">
        <div class="mb-3">
          <label for="name" class="form-label text-light">Your Name</label>
          <input v-model="formData.name" type="text" class="form-control" id="name" required />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label text-light">Your Email</label>
          <input v-model="formData.email" type="email" class="form-control" id="email" required />
        </div>
        <div class="mb-3">
          <label for="message" class="form-label text-light">Your Message</label>
          <textarea v-model="formData.message" class="form-control" id="message" rows="4" required></textarea>
        </div>
        <button type="submit" class="btn btn-light">Send Email</button>
      </form>

      <br/>
      <br/>
      <div class="container other-info">
        <h3>Or instead...</h3>
        <button class="dark-button" @click="openWhatsApp">Contact via WhatsApp</button>
  
        <!-- Instagram Button -->
        <button class="dark-button" @click="openInstagram">Visit Instagram</button>
     
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    
  </div>
</template>

<script>
import { ref, onBeforeUnmount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import emailjs from 'emailjs-com';

export default {
  setup() {
    const router = useRouter();

    const formData = ref({
      name: '',
      email: '',
      message: ''
    });

    const isMounted = ref(true);

    onMounted(() => {
      // Set isMounted to true when the component is mounted
      isMounted.value = true;
    });

    onBeforeUnmount(() => {
      // Set isMounted to false when the component is about to unmount
      isMounted.value = false;
    });

    const sendEmail = async () => {
      try {
        const templateParams = {
          message: formData.value.message,
          email: formData.value.email,
          from_name: formData.value.name
        };

        const response = await emailjs.send(
          'service_dfucllu',
          'template_pkzmmdc',
          templateParams,
          'bT_Lua-S_URHyUf5u'
        );

        console.log('Email sent:', response);

        // Update the UI or perform other actions instead of a page reload
        if (isMounted.value) {
          router.push('/'); // Replace '/' with the actual path of your home page

        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };

    return { formData, sendEmail };
  },
    methods: {
    openWhatsApp() {
      const phoneNumber = '+447464873211';
      const message = encodeURIComponent(``);
      const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(whatsappLink, '_blank');
    },
    openInstagram() {
      const instagramLink = 'https://instagram.com/bardenmotorgroup?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr';
      window.open(instagramLink, '_blank');
    },

  }
};
</script>

<style scoped>
  .container-fluid{
    background-color: #000717; /* Dark background color */
  }
  .other-info {
    background-color: #fff; /* Bright background color */
    padding: 20px; /* Padding for the container */
    border-radius: 8px; /* Optional: Add rounded corners for a softer look */
    text-align: center; /* Center-align content */
    max-width: 750px;
    margin: auto;
    position: relative;
  }
  form {
    background-color: #C10043; /* Dark form background color */
    padding: 20px;
    border-radius: 10px;
    max-width: 750px;
    margin: auto;
  }

  label, .text-light {
    color: #ffffff; /* White text color */
  }

  input, textarea {
    background-color: #58172e; /* Dark input background color */
    color: #ffffff; /* White text color */
    border: none;
    padding: 1em;
  }



.container-fluid{
    min-height: 100vh;
}

.title{
  color: white;
  padding: 0.5em;
}
.title h1{
  margin-top: 2em;
}
.dark-button {
  background-color: #333; /* Dark background color */
  color: #fff; /* Text color */
  border: 2px solid #555; /* Border color */
  padding: 15px; /* Padding for the button */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Add pointer cursor for interaction */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition effect */
  display: block;
  margin: auto;
  margin-bottom: 10px; /* Margin between buttons */

}

.dark-button:hover {
  background-color: #555; /* Darker background color on hover */
  color: #fff; /* Lighter text color on hover */
}
</style>