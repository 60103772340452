<template>
  <div class="container-fluid finance-cont-fluid">
    <div class="container finance-cont">
      <h1 id="title">Get in contact for a review of your vehicle</h1>
    </div>
  <div class="buy-your-car container-fluid">
    <div class="container">
      <div class="row main-title">
        <!-- First column (1/3) -->
        <div class="col-12 col-sm-4">
          <div class="buy-your-car-column">
            <!-- Your content for the first column goes here -->
            <img id="sell-car-img" src="../assets/money.jpeg" alt="">
          </div>
        </div>

        <!-- Second column (2/3) -->
        <div class="col-12 col-sm-8">
          <div class="buy-your-car-column">
            <!-- Your content for the second column goes here -->
            <h1>Want to sell your car?</h1>
            <p>Look below to learn how to sell your car</p>

            <div class="title-info">
              <p>
                At Barden Motor Group Ltd, we simplify the process of selling your used car. Whether you're upgrading or parting ways, our platform ensures a transparent and hassle-free experience. Our team provides fair valuations, considering your vehicle's unique features. With a user-friendly interface and a commitment to integrity, we offer a convenient and efficient solution for those looking to sell their cars confidently. Trust Barden Motor Group Ltd for a seamless transition in selling your used car.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="other">
        <button class="whatsappButton btn">Contact via Whatsapp</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
mounted() {
  // Replace '123456789' with the desired phone number
  const phoneNumber = '+447464873211';

  // Replace 'Hello World' with the pre-determined message
  const message = 'Hello! I would like to sell my car and discuss this further. Looking forward to hearing from you!';

  const encodedMessage = encodeURIComponent(message);

  const whatsappButton = document.querySelector('.whatsappButton');

  whatsappButton.addEventListener('click', function() {
    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open the link in a new tab or window
    window.open(whatsappLink, '_blank');
  });
}
}
</script>

<style scoped>
#sell-car-img {
  width: 400px;
  height: auto;
}

@media screen and (max-width: 767px) {
  #sell-car-img {
    width: 100%;
  }
}

.buy-your-car {
  background-color: #000717;
  min-height: 100vh;
  color: #ffffff;
  padding-bottom: 100px;
}
.finance-cont-fluid {
  background-color: #000717;
}

#title {
  text-align: center;
  color: white;
  padding: 1rem;
  width: 100%;
  background-color: #6b0080;
}


.buy-your-car-column > h1 {
  padding: 10px;
  padding-left: 0px;
}

.title-info {
  background-color: #29292b;
  padding: 20px;
  border-radius: 8px;
  min-height: 18rem;
}

.title-info p {
  color: #eee;
}

.main-title {
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.whatsappButton {
  background-color: #333; /* Dark background color */
  color: #fff; /* Text color */
  border: 2px solid #555; /* Border color */
  padding: 15px; /* Padding for the button */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Add pointer cursor for interaction */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition effect */
  width: 100%; /* Full width by default */
}

/* Media query for screens larger than 992px (laptop size) */
@media (min-width: 992px) {
  .whatsappButton {
    width: auto; /* Auto width for screens larger than 992px */
    margin: 0 auto; /* Center the button */
    max-width: 400px; /* Limit maximum width */
    padding: 20px; /* Generous padding for larger screens */
  }
}

.whatsappButton:hover {
  background-color: #555; /* Darker background color on hover */
  color: #fff; /* Lighter text color on hover */
}
</style>
