<template>
  <Header/>
  <router-view></router-view>
  <Footer/>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default{
  components: {
    Header,
    Footer
  }
}
</script>
<style>
*{
  padding: 0px;
  margin: 0px;
}

</style>
