<template>
    <div class="container car-product postcard">

          <!-- Other Images -->
             <!-- Other Images -->
             <div class="splide-cont-bg">
              <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="splide-cont" >
                <SplideSlide class="text-center">
                  <img :src="mainImageURL" alt="Car Image" class="auto-size">
                </SplideSlide>
                <SplideSlide v-for="(image, index) in otherImages || []" :key="index" class="text-center">
                  <img v-if="image && image.fields && image.fields.file" :src="image.fields.file.url" alt="Other Image" class="auto-size">
              </SplideSlide>
              
              </Splide>
             </div>
            
     
 
  

      <div class="container inner-info">
        <router-link :to="generateDynamicPath()" class="car-title"><h3>{{ Price }} - {{Name}}</h3></router-link>
        <button class="btn no-margin"></button>
        <p>{{ shortenedString }}</p>
        <br/>
        <div class="text-white extra-info-cont">
            <p>{{entry.odometer}}</p>
            <p>{{entry.fuelType}}</p>
            <p>{{entry.transmission}}</p>
            <p>Doors: {{entry.doors}}</p>
        
        </div>
        <div class="extra-btns">
          <a class="btn btn-success" @click="whatsappClick(Name)">Whatsapp</a>
        </div>
      </div>
</div>
</template>

<script>
import { useAllContentfulEntries } from '@/composables/getEntries';
import { ref, computed } from 'vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';


export default {
  setup(props) {
    const { entries, loading, error } = useAllContentfulEntries();


    const shortenedString = computed(() => {
      if (typeof props.Desc === 'string') {
        return props.Desc.length > 200
               ? props.Desc.substring(0, 200) + '...'
               : props.Desc;
      }
      return '';  // Return a default value if Desc is not a string
    });

    return { entries, loading, error, shortenedString};
  },
  components:{
    Splide,
    SplideSlide
  },
  props: ['Name', 'Desc', 'Price', 'mainImageURL', 'otherImages', 'ID', 'entry'],
  methods: {
    generateDynamicPath(props) {
      // Your logic to generate the dynamic path
      return `/cars/${this.ID}`;
    },
    whatsappClick(name){
      console.log("Working")
      const phoneNumber = '+447464873211';
      const message = encodeURIComponent(`Hi, i would like to enquire about this car: ${name}`);
      const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(whatsappLink, '_blank');
    }
  },
};
</script>

<style scoped>
.auto-size {
  width: 100%;           /* Makes the image take full width of its container */
  height: 100%;          /* Makes the image take full height of its container */
  max-height: 500px;     /* Sets a maximum height for the image */
  object-fit: cover;     /* Covers the entire container, possibly cropping the image */
  object-position: center; /* Focuses on the center of the image */
}
.car-product {
  display: flex;
  width: 100%;
  padding: 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  margin-top: 2rem;
  background-color: #eee;
  background-repeat: none;
  background-size: cover;
  height: 100%; /* Make sure the parent container has a height set */
}
.extra-info-cont {
  display: inline-flex;
}
.no-margin{
  color: red;
  margin: 0px;
  padding: 0px;
}
.car-title{
  color: white;
  margin: 20px;
  font-weight: bold;
}
.extra-info-cont > p {
  margin-right: 10px;
  background-color: #333;
  padding: 5px;
  border-radius: 5px;
}
.inner-info {
  display: block;
}
.splide-cont-bg {
  min-width: 300px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden; /* Ensures the images don't overflow the container */
  height: 100%; /* Make sure the parent container has a height set */
}

.btn-secondary{
  margin: 0px !important;
}
.splide-cont {
  margin: auto;
  width: auto;
  max-width: 500px;
  height: auto; /* Ensures the Splide container takes the full height of the viewport */
}
@media only screen and (max-width: 768px) {
  .car-product {
    display: block;
  }
}

.splide-cont-bg {
  background-color: white;
}

.inner-info {
  display: inline-block;
}

.inner-info > h3 {
  display: inline-block;
  color: #ffffff;
  margin: 10px;
  padding: 5px;
}
.inner-info > button {
  display: block;
  margin-left: 10px;
}

.inner-info > p {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  color: #ffffff;
}
.extra-btns > a {
  margin-right: 10px;
  margin-bottom: 10px;
}
.postcard {
  background-color: #18151f;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  height: 100%; /* Make sure the parent container has a height set */
}
</style>
